<template>
  <div class="operation-box" v-if="currentTemp" ref='page'>
    <div class="operation-type-box">
      <p>选择模版</p>
      <p class="temp-name">{{templateList.find(item=>item.type === currentTemp.type).name}}</p>
    </div>
    <div class="temp-list">
      <div @click="currentTemp.type = temp.type" class="temp-item" v-for="(temp,index) in templateList" :key="index" :class="{current: currentTemp.type === temp.type}">
        <img src="@/assets/decorateIcon/add-img-0.png" class="temp-img" v-if="temp.type === 0" alt />
        <img src="@/assets/decorateIcon/add-img-1.png" class="temp-img" v-if="temp.type === 1" alt />
        <img src="@/assets/decorateIcon/add-img-2.png" class="temp-img" v-if="temp.type === 2" alt />
        <div class="temp-name">{{temp.name}}</div>
      </div>
    </div>
    <div class="upload-box" v-if='loaded'>
      <div class="upload-head">
        <p class="upload-head-title">添加图片</p>
        <p class="upload-head-desc">{{templateList.find(item=>item.type === currentTemp.type).desc}}</p>
      </div>
      <div class="img-box">
        <a-spin :spinning="imgBoxLoading" v-if="currentTemp.children.length">
          <draggable class="img-draggable" :list="currentTemp.children" group="img-list" draggable=".img-item-box">
            <div v-for="(item,index) in currentTemp.children" :key="index" style='margin-bottom:12px'>
              <div class="img-item-box">
                <a-icon type="close-circle" class="delete-item" @click="deleteItem(index)" />
                <div class="uploader-card">
                  <img v-if="item.imgUrl" :src="baseUrl + item.imgUrl" class="img" />
                  <div v-else @click='selectImg(false,index)'>
                    <a-icon type="plus" />
                  </div>
                  <p class="desc" @click='selectImg(false,index)' v-if="item.imgUrl">更换图片</p>
                </div>
                <div class="input-box">
                  <div class="input-content">
                    <p class="input-label">标题：</p>
                    <a-input v-model="item.title" placeholder="建议10字内，可不填"></a-input>
                  </div>
                  <div class="input-content" style="margin-top:8px">
                    <p>链接：</p>
                    <a-button type="link" style="padding: 0" @click="selectLink(index)" v-if="!item.linkType">选择跳转到的页面{{item.linkType}}</a-button>
                    <a :href="item.linkUrl" target="_blank" v-if="item.linkType===1" class='link-a'>{{item.linkUrl}}</a>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 2&&item.groupId' :style="{color: getGroupName(item.groupId)?'#000':'red'}">{{getGroupName(item.groupId)||'未找到分组，请重选'}}</div>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 3'>拼团活动列表</div>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 4'>砍价活动列表</div>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 5'>秒杀活动列表</div>
                    <div class='link-a' style='color:red' v-if=" item.linkType === 6  && item.id.indexOf('xfxproductid') !==-1">未找到商品，请重选</div>
                    <div class='link-a' @click="goGoodsDetail(item.id)" v-if="item.linkType === 6 && item.id.indexOf('xfxproductid') === -1">{{item.name}}</div>
                    <div style='color: #000;' class='link-a' v-if='item.linkType === 8'>直播间列表</div>
                    <a-button type="link" style="padding: 0" @click="selectLink(index)" v-if="item.linkType">修改</a-button>
                    <a-button type="link" style="padding-right: 0" @click="deleteLink(index)" v-if="item.linkType">重置</a-button>
                  </div>
                </div>
              </div>
              <!-- <div class='tips' v-if="item.linkType === 6 && item.id.indexOf('xfxproductid') !==-1">注：模版数据！请自行替换跳转链接</div>
              <div class='tips' v-if="item.linkType === 2 && item.groupId.indexOf('templateGroup') !==-1">注：模版数据！请自行替换跳转链接</div> -->
            </div>
          </draggable>
        </a-spin>

        <div v-if="currentTemp.children.length < 10">
          <a-button icon="plus" style="width: calc( 100% - 28px );height:40px;margin: 8px 14px;" type="primary" :loading="addLoading" @click='selectImg(true)'>添加图片</a-button>
        </div>
      </div>
    </div>
    <div class='img-style-box' v-if="currentTemp.children.length">
      <div class='img-style-item'>
        <div class='label'>图片圆角：</div>
        <a-switch v-model="currentTemp.isRound" checked-children="开" un-checked-children="关" />
      </div>
      <div class='img-style-item'>
        <div class='label'>图片高度：</div>
        <a-slider :default-value="0" :min='80' :max='1200' :step='2' v-model="currentTemp.height" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{currentTemp.height}}px</span>
      </div>
      <div class='img-style-item'>
        <div class='label'>模块背景色：</div>
        <a-popover title="">
          <template slot="content">
            <Sketch v-model="currentTemp.boxBgColor" @input="updateBoxBgColorValue"></Sketch>
          </template>
          <div class='bg-btn' :style='{background: currentTemp.boxBgColor}'></div>
        </a-popover>
      </div>
      <div class='img-style-item'>
        <div class='label'>模块上下间距：</div>
        <a-slider :default-value="0" :min='0' :max='50' :step='1' v-model="currentTemp.boxPaddingH" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{currentTemp.boxPaddingH}}px</span>
      </div>
      <div class='img-style-item'>
        <div class='label'>模块左右边距：</div>
        <a-slider :default-value="0" :min='0' :max='50' :step='1' v-model="currentTemp.boxPaddingV" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{currentTemp.boxPaddingV}}px</span>
      </div>
      <div class='img-style-item' v-if="props.type !== 1">
        <div class='label'>图片间距：</div>
        <a-slider :default-value="0" :min='0' :max='50' :step='1' v-model="currentTemp.itemMargin" tooltipPlacement='right' style='width:180px;margin:0 12px;' />
        <span>{{currentTemp.itemMargin}}px</span>
      </div>
      <div class='img-style-item'>
        <div class='label'>标题背景色：</div>
        <a-popover title="">
          <template slot="content">
            <Sketch v-model="currentTemp.bgColor" @input="updateBgColorValue"></Sketch>
          </template>
          <div class='bg-btn' :style='{background: currentTemp.bgColor}'></div>
        </a-popover>
      </div>
      <div class='img-style-item'>
        <div class='label'>标题字体色：</div>
        <a-popover title="">
          <template slot="content">
            <Sketch v-model="currentTemp.textColor" @input='updateTextColorValue'></Sketch>
          </template>
          <div class='bg-btn' :style='{background: currentTemp.textColor}'></div>
        </a-popover>
      </div>
      <div class='img-style-item'>
        <div class='label'>标题字大小：</div>
        <a-radio-group v-model="currentTemp.fontSize">
          <a-radio :value="12">
            小
          </a-radio>
          <a-radio :value="14">
            中
          </a-radio>
          <a-radio :value="16">
            大
          </a-radio>
        </a-radio-group>
      </div>
    </div>
    <a-modal :getContainer="getContainer" v-model="selectLinkModal" title="选择跳转到的页面" @ok="handleOk" @cancel='handleCancel'>
      <a-form-model ref="selectForm" :model="linkFrom" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-model-item label="类型" prop="linkType">
          <a-select v-model="linkFrom.linkType" placeholder="请选择跳转类型" @change='selectLinkType' style='width: 300px;'>
            <a-select-option :value="6">商品详情</a-select-option>
            <a-select-option :value="2">商品分组</a-select-option>
            <a-select-option :value="7">自定义装修页面</a-select-option>
            <a-select-option :value="3">拼团活动列表</a-select-option>
            <a-select-option :value="4">砍价活动列表</a-select-option>
            <a-select-option :value="5">秒杀活动列表</a-select-option>
            <a-select-option :value="8" v-if='isPlatform'>直播间列表</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="商品分组" prop="groupId" v-if="linkFrom.linkType===2">
          <a-select v-model="linkFrom.groupId" placeholder="请选择">
            <a-select-option :value="item.productGroupId" :key='item.productGroupId' v-for='item in groupList'>{{item.groupName}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="装修页面" prop="templateId" v-if="linkFrom.linkType===7">
            <a-select v-model="linkFrom.templateId" placeholder="请选择">
              <a-select-option :value="item.shopDesignId" :key='item.shopDesignId' v-for='item in secendTemplateList'>{{item.title}}</a-select-option>
            </a-select>
          </a-form-model-item>
        <a-form-model-item label='商品' prop='id' v-if='linkFrom.linkType === 6 '>
          <div class='flex-row'>
            <div class='link-a' v-if="linkFrom.id" @click="goGoodsDetail(linkFrom.id)" style='max-width: 300px'>{{linkFrom.name}}</div>
            <a-button type='link' @click='selectLinkType(6)' style='padding-right: 0'>{{linkFrom.id?'修改':'请选择商品'}}</a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal class='select-goods-modal' v-model="selectGoodsModal" :getContainer="getContainer" title="选择商品" :maskClosable="false" @ok="selectedDone">
      <div class="search-box">
        <a-input placeholder="搜索商品名称" style="flex:1;" v-model="productName"></a-input>
        <a-button type="primary" style="margin:0 16px;" @click="getTableList">搜索</a-button>
        <a-button @click="resetSearch">重置</a-button>
      </div>
      <a-table :pagination="pagination" :columns="columns" :dataSource="allGoods" :rowKey="record => record.productId" :loading="selectGoodsLoading" :row-selection="{ selectedRowKeys: goodsId[currentIndex]||[], onChange: onSelectChange,type:'radio'}" @change="handleTableChange">
        <template slot="name" slot-scope="text,record">
          <div class="table-goods-info">
            <base-img class="goods-img" :src="record.productImg" width="60" height="60" />
            <a-button type="link" class="goods-name" @click="goGoodsDetail(record.productId)">{{record.name}}</a-button>
          </div>
        </template>
      </a-table>
    </a-modal>
    <select-img-dialog idName='page-box' v-if='showSelectImg' :show='showSelectImg' @change='handleSelectDone' :initSelectData='initSelectData' :multiple='multiple' :max='10'></select-img-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, computed, onMounted } from '@vue/composition-api'
import { Sketch } from 'vue-color'
import draggable from 'vuedraggable'
// import ossUpload from '@/utils/oss'
import { common, goods, shop } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { isPlatform } from '../../../utils/tools'
export default {
  components: {
    draggable,
    Sketch
  },
  props: {
    props: {
      type: Object
    }
  },
  setup (props, { root, emit }) {
    const rules = {
      linkType: [{ required: true, message: '请选择', trigger: 'change' }],
      groupId: [{ required: true, message: '请选择', trigger: 'change' }],
      id: [{ required: true, message: '请选择', trigger: 'change' }]
    }
    const templateList = [
      {
        name: '一行一个',
        desc: '最多添加 10 个广告，鼠标拖拽调整广告顺序，建议宽度750像素',
        type: 0
      },
      {
        name: '轮播海报',
        desc: '最多添加 10 个广告，鼠标拖拽调整广告顺序，建议尺寸750x350像素',
        type: 1
      },
      {
        name: '大图横向滚动',
        desc: '最多添加 10 个广告，鼠标拖拽调整广告顺序，建议宽度670像素',
        type: 2
      }
    ]
    const columns = [
      {
        title: '商品',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' }
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' }
      }
    ]

    const baseApi = process.env.VUE_APP_API
    const baseUrl = process.env.VUE_APP_IMG
    const state = reactive({
      productName: '',
      groupList: [],
      selectForm: null,
      linkFrom: {},
      secendTemplateList: [],
      selectLinkModal: false,
      currentIndex: null,
      pagination: {
        current: 1,
        pageSize: 5,
        total: 0,
        size: 'small'
      },
      imgBoxLoading: null,
      addLoading: false,
      selectGoodsModal: false,
      selectGoodsLoading: false,
      allGoods: [],
      goodsId: [],
      selectGood: {},
      showSelectImg: false,
      multiple: true,
      initSelectData: [],
      currentImgIndex: undefined,
      loaded: false
    })
    const currentTemp = computed({
      get: () => {
        return props.props
      },
      set: (data) => {
      }
    })
    function deleteItem (index) {
      currentTemp.value.children.splice(index, 1)
    }
    function updateBoxBgColorValue (e) {
      root.$set(
        currentTemp.value,
        'boxBgColor',
        `rgba(${e.rgba.r},${e.rgba.g},${e.rgba.b},${e.rgba.a})`
      )
    }
    function handleTableChange (page) {
      state.pagination = page
      getTableList()
    }
    async function getTemplateList () {
      let { code, data } = await shop.getTemplateList({
        designDataFlag: true
      })
      if (code === '00000') {
        state.secendTemplateList = data
        console.log(data)
      }
    }
    onMounted(async () => {
      state.goodsId = currentTemp.value.children.map((item) => {
        return [item.id] || []
      })
      await Promise.all([selectGoodsType(), getTemplateList()])
      state.loaded = true
    })
    function resetSearch () {
      state.productName = ''
      getTableList()
    }
    function onSelectChange (selectKeys, selectRows) {
      state.selectGood = selectRows[0]
      state.goodsId[state.currentIndex] = selectKeys
      root.$set(
        state,
        'goodsId',
        state.goodsId.map((item, index) => {
          if (index === state.currentIndex) {
            item = selectKeys
          }
          return item
        })
      )
    }
    function selectedDone () {
      root.$set(state.linkFrom, 'id', state.goodsId[state.currentIndex][0])
      root.$set(state.linkFrom, 'name', state.selectGood.name)
      state.selectGoodsModal = false
    }
    async function getTableList () {
      state.selectGoodsLoading = true
      let { code, msg, data, page } = await goods.getGoodsList({
        state: 1,
        name: state.productName,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.selectGoodsLoading = false
      if (code === '00000') {
        state.allGoods = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function selectLink (index) {
      state.currentIndex = index
      state.linkFrom = Object.assign({}, currentTemp.value.children[state.currentIndex])
      state.selectLinkModal = true
    }
    function handleOk () {
      state.selectForm.validate(async (valid) => {
        if (valid) {
          currentTemp.value.children[state.currentIndex] = state.linkFrom
          state.selectLinkModal = false
        }
      })
    }
    function handleCancel () {
      state.linkFrom = {}
      state.goodsId = currentTemp.value.children.map((item) => {
        return [item.id] || []
      })
    }
    function handleSelectDone (e) {
      if (state.currentImgIndex !== undefined && !state.multiple) {
        currentTemp.value.children[state.currentImgIndex].imgUrl = e[0]
        state.imgBoxLoading = false
      } else {
        currentTemp.value.children = e.map((x) => {
          return {
            imgUrl: x,
            linkUrl: '',
            title: ''
          }
        })
        state.addLoading = false
      }
      state.showSelectImg = false
    }
    function updateBgColorValue (e) {
      root.$set(
        currentTemp.value,
        'bgColor',
        `rgba(${e.rgba.r},${e.rgba.g},${e.rgba.b},${e.rgba.a})`
      )
    }
    function updateTextColorValue (e) {
      root.$set(
        currentTemp.value,
        'textColor',
        `rgba(${e.rgba.r},${e.rgba.g},${e.rgba.b},${e.rgba.a})`
      )
    }
    async function selectGoodsType () {
      state.groupLoading = true
      let { code, msg, data } = await common.getProductGroupByShopId({
        shopId: getSession(SHOPID)
      })
      state.groupLoading = false
      if (code === '00000') {
        state.groupList = data
      } else {
        root.$message.error(msg || '获取分组数据失败')
      }
    }
    function selectLinkType (e) {
      if (e === 6) {
        state.selectGoodsModal = true
        getTableList()
      }
    }
    function goGoodsDetail (id) {
      root.$router.push(`/goods/goods_detail/${id}`)
    }
    function deleteLink (index) {
      let obj = currentTemp.value.children[index]
      if (obj.linkType === 1) {
        obj.linkType = undefined
        obj.linkUrl = ''
      } else if (obj.linkType === 2) {
        obj.linkType = undefined
        obj.groupId = ''
      } else {
        obj.linkType = undefined
      }
    }
    function selectImg (flag, index) {
      state.multiple = flag
      state.showSelectImg = true
      if (!flag) {
        state.currentImgIndex = index
        state.initSelectData = [currentTemp.value.children[index].imgUrl]
      } else {
        state.initSelectData = currentTemp.value.children.map((x) => x.imgUrl)
      }
    }
    function getContainer () {
      return document.getElementById('page-box')
    }
    function getGroupName (id) {
      return state.groupList.length && state.groupList.find(val => val.productGroupId === id) ? '商品分组 - ' + state.groupList.find(val => val.productGroupId === id).groupName : null
    }

    return {
      isPlatform,
      rules,
      columns,
      currentTemp,
      baseApi,
      baseUrl,
      ...toRefs(state),
      templateList,
      deleteItem,
      selectLink,
      handleOk,
      handleCancel,
      updateBgColorValue,
      updateTextColorValue,
      selectGoodsType,
      resetSearch,
      updateBoxBgColorValue,
      getTableList,
      onSelectChange,
      handleTableChange,
      selectedDone,
      selectLinkType,
      goGoodsDetail,
      deleteLink,
      handleSelectDone,
      selectImg,
      getGroupName,
      getContainer,
      getTemplateList
    }
  }
}
</script>
<style lang="less" scoped>
.operation-box {
  background: #fff;
  .operation-type-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    padding: 14px 14px 0;
    .temp-name {
      font-weight: bold;
      color: #000;
      margin-left: 16px;
      font-size: 16px;
    }
  }
  .temp-list {
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ddd;
    .current {
      border: 1px solid #1890ff;
    }
    .temp-item {
      cursor: pointer;
      box-sizing: border-box;
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% / 3);
      font-size: 12px;
      .temp-img {
        width: 88px;
        object-fit: contain;
      }
      .temp-name {
        margin-top: 8px;
      }
    }
  }
  .upload-box {
    padding-top: 24px;
    .upload-head {
      padding: 0 14px;
      .upload-head-title {
        font-size: 18px;
        margin-bottom: 8px;
      }
      .upload-head-desc {
        font-size: 12px;
        color: #666;
        padding-bottom: 8px;
      }
    }
    .img-box {
      background: #eee;
      margin-top: 8px;
      padding: 14px 0;
      .img-item-box {
        position: relative;
        display: flex;
        align-items: center;
        height: 88px;
        margin: 0 12px;
        padding: 14px;
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
        &:hover .delete-item {
          display: block;
        }
        .input-box {
          flex: 1;
          .input-content {
            display: flex;
            align-items: center;

            .input-label {
              flex-shrink: 0;
            }
          }
        }
        .delete-item {
          display: none;
          position: absolute;
          top: -6px;
          right: -8px;
          font-size: 20px;
          background: #fff;
          border-radius: 50%;
          z-index: 1;
          color: #666;
        }
        .uploader-card {
          width: 72px;
          height: 72px;
          position: relative;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px dashed #ddd;
          border-radius: 4px;
          ::v-deep .img {
            display: block;
            width: 70px;
            height: 70px;
            object-fit: contain;
            position: relative;
          }
          span {
            padding: 0;
            position: relative;
          }
          .desc {
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            font-size: 12px;
            line-height: 18px;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  .img-style-box {
    background: #fff;
    padding: 32px 14px 200px;
    .img-style-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .bg-btn {
        width: 32px;
        height: 24px;
        border: 1px solid #ddd;
        cursor: pointer;
      }
      .text-color-btn {
        font-size: 14px;
        cursor: pointer;
      }
      ::v-deep .ant-popover-inner-content {
        padding: 0;
      }
      .label {
        width: 8em;
        text-align: right;
      }
      ::v-deep .ant-slider-handle {
        border-color: #189ff0;
      }
      ::v-deep .ant-slider-track {
        background-color: #189ff0;
      }
      ::v-deep .ant-slider-rail {
        background-color: #c1c1c1;
      }
    }
  }
}

.search-box {
  display: flex;
  align-items: center;
  margin: 12px 16px;
  justify-content: flex-end;
}
::v-deep .ant-form-item {
  margin-bottom: 12px;
}
.table-goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    width: 60px;
  }
  .goods-name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
::v-deep .ant-table-tbody > tr > td {
  padding: 4px 16px;
}
.select-goods-modal ::v-deep .ant-modal-body {
  padding: 0;
}
.link-a {
  cursor: pointer;
  color: #189ff0;
  margin-right: 12px;
  max-width: 138px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.flex-row {
  display: flex;
  align-items: center;
  height: 40px;
}
.tips {
  padding: 8px 16px;
  color: red;
  font-size: 12px;
}
</style>
